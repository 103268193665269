body {
  background-color: #000;
  .fade-in {
    animation: fadeIn 1.5s;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-1vw);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}