.platform-wrap {
    padding-bottom: 13vw;
    .big-title {
        font-family: 'NanumSquareEB';
        color: #fff;
        text-align: center;
        font-size: 2.8vw;
        line-height: 3.65vw;
        margin-top: 14.5vw;
        margin-bottom: 8.5vw;
    }
    > .top {
        padding-left: 13.5vw;
        padding-right: 13.5vw;
        text-align: center;
        position: relative;
        > video {
            position: absolute;
            width: 60%;
            top: 0%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.6;
            z-index: -1;
        }
        > .image-title {
            width: 36.45vw;
            margin-bottom: 4.6vw;
        }
        > .info {
            display: flex;
            flex-direction: row;
            & + .info {
                margin-top: 2vw;
            }
            h1 {
                color: #fff;
                font-family: 'NanumSquareEB';
                font-size: 2.6vw;
            }
            > .left {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                
                > .jisoo, .celeb {
                    text-align: center;
                    > img {
                        width: 23.5vw;
                    }
                    > p {
                        color: #ffffffb3;
                        margin-top: 1.5vw;
                        font-size: 0.7vw;
                    }
                }
                > h1 {
                    text-align: left;
                }
                > .sub-info {
                    text-align: left;
                    > p {
                        color: #fff;
                        font-family: 'NanumSquareEB';
                        margin-top: 3.5vw;
                        font-size: 1.25vw;
                        line-height: 2.3vw;
                    }
                    > span {
                        color: #fff;
                        font-size: 1.25vw;
                        line-height: 2.3vw;
                    }
                }
            }
            > .right {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                text-align: right;
                > pre {
                    margin-top: 2vw;
                    color: #fff;
                    font-size: 1.25vw;
                    line-height: 2.2vw;
                }
                > .wte {
                    position: relative;
                    width: 17.3vw;
                    margin-left: 40%;
                    max-width: 100%;
                    overflow: visible;
                    > .faker {
                        width: 17.3vw;
                    }
                    > .other {
                        display: none;
                    }
                    &.ani {
                        > .other {
                            display: block;
                            width: 8vw;
                            position: absolute;
                            transform: translate(-50%, -50%);
                            left: 50%;
                            top: 50%;
                            z-index: -1;
                            &:nth-child(1) {
                                animation: wte01 0.8s;
                                left: -15%;
                                top: 20%;
                            }
                            &:nth-child(2) {
                                animation: wte02 0.8s;
                                left: -40%;
                                top: 50%;
                            }
                            &:nth-child(3) {
                                animation: wte03 0.8s;
                                left: -15%;
                                top: 80%;
                            }
                            &:nth-child(4) {
                                animation: wte04 0.8s;
                                left: 120%;
                                top: 30%;
                            }
                            &:nth-child(5) {
                                animation: wte05 0.8s;
                                left: 120%;
                                top: 80%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes wte01 {
    from {
        opacity: 0;
        left: 50%;
        top: 50%;
    }
    to {
        opacity: 1;
        left: -15%;
        top: 20%;
    }
}

@keyframes wte02 {
    from {
        opacity: 0;
        left: 50%;
        top: 50%;
    }
    to {
        opacity: 1;
        left: -40%;
        top: 50%;
    }
}

@keyframes wte03 {
    from {
        opacity: 0;
        left: 50%;
        top: 50%;
    }
    to {
        opacity: 1;
        left: -15%;
        top: 80%;
    }
}

@keyframes wte04 {
    from {
        opacity: 0;
        left: 50%;
        top: 50%;
    }
    to {
        opacity: 1;
        left: 120%;
        top: 30%;
    }
}

@keyframes wte05 {
    from {
        opacity: 0;
        left: 50%;
        top: 50%;
    }
    to {
        opacity: 1;
        left: 120%;
        top: 80%;
    }
}

@media screen and (max-width: 800px) {
    .platform-wrap {
        padding-bottom: 13vw;
        .big-title {
            font-size: 6.4vw;
            line-height: 3.65vw;
            margin-top: 19.2vw;
            margin-bottom: 32vw;
        }
        > .top {
            padding-left: 5.3vw;
            padding-right: 5.3vw;
            text-align: center;
            > .image-title {
                width: 61.6vw;
                margin-bottom: 13.1vw;
            }
            > video {
                width: 100%;
            }
            > .info {
                display: flex;
                flex-direction: column;
                &:nth-child(4) {
                    flex-direction: column-reverse;
                }
                & + .info {
                    margin-top:10.6vw;
                }
                h1 {
                    font-size: 5.3vw;
                }
                > .left {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    justify-content: center;
                    
                    > .jisoo, .celeb {
                        text-align: center;
                        > img {
                            width: 57.6vw;
                        }
                        > p {
                            margin-top: 4vw;
                            font-size: 2.6vw;
                        }
                    }
                    > .jisoo:before {
                        content: "[ Short Form ]";
                        display: block;
                        color: #fff;
                        font-size: 5.3vw;
                        font-family: 'NanumSquareEB';
                        margin-bottom: 5.3vw;
                    }
                    > .celeb:before {
                        content: "[ Celeb ]";
                        display: block;
                        color: #fff;
                        font-size: 5.3vw;
                        font-family: 'NanumSquareEB';
                        margin-bottom: 5.3vw;
                    }
                    > h1 {
                        display: none;
                    }
                    > .sub-info {
                        text-align: left;
                        > p {
                            color: #fff;
                            font-family: 'NanumSquareEB';
                            margin-top: 4vw;
                            font-size: 3.7vw;
                            line-height: 6.72vw;
                        }
                        > span {
                            color: #fff;
                            font-size: 3.7vw;
                            line-height: 6.72vw;
                        }
                    }
                }
                > .right {
                    text-align: center;
                    > h1 {
                        display: none;
                    }
                    > pre {
                        margin-top: 8vw;
                        font-size: 3.7vw;
                        line-height: 6.72vw;
                        white-space: normal;
                    }
                    > .wte {
                        width: 100%;
                        margin-left: 0;
                        overflow: hidden;
                        &:before {
                            content: "[ Watch to Earn ]";
                            display: block;
                            color: #fff;
                            font-size: 5.3vw;
                            font-family: 'NanumSquareEB';
                            margin-bottom: 5.3vw;
                        }
                        > .faker {
                            width: 30vw;
                        }
                        > .other {
                            display: none;
                        }
                        &.ani {
                            > .other {
                                display: block;
                                width: 18vw;
                                position: absolute;
                                transform: translate(-50%, -50%);
                                left: 50%;
                                top: 50%;
                                z-index: 10;
                                &:nth-child(1) {
                                    animation: wtem01 0.8s;
                                    left: 20%;
                                    top: 45%;
                                }
                                &:nth-child(2) {
                                    animation: wtem02 0.8s;
                                    left: 10%;
                                    top: 70%;
                                }
                                &:nth-child(3) {
                                    animation: wtem03 0.8s;
                                    left: 24%;
                                    top: 80%;
                                }
                                &:nth-child(4) {
                                    animation: wtem04 0.8s;
                                    left: 76%;
                                    top: 48%;
                                }
                                &:nth-child(5) {
                                    animation: wtem05 0.8s;
                                    left: 85%;
                                    top: 80%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes wtem01 {
    from {
        opacity: 0;
        left: 50%;
        top: 50%;
    }
    to {
        opacity: 1;
        left: 20%;
        top: 45%;
    }
}

@keyframes wtem02 {
    from {
        opacity: 0;
        left: 50%;
        top: 50%;
    }
    to {
        opacity: 1;
        left: 10%;
        top: 70%;
    }
}

@keyframes wtem03 {
    from {
        opacity: 0;
        left: 50%;
        top: 50%;
    }
    to {
        opacity: 1;
        left: 24%;
        top: 80%;
    }
}

@keyframes wtem04 {
    from {
        opacity: 0;
        left: 50%;
        top: 50%;
    }
    to {
        opacity: 1;
        left: 76%;
        top: 48%;
    }
}

@keyframes wtem05 {
    from {
        opacity: 0;
        left: 50%;
        top: 50%;
    }
    to {
        opacity: 1;
        left: 85%;
        top: 80%;
    }
}