.top-wrap {
    padding: 4.1vw 13.5vw;
    > .body {
        display: flex;
        flex-direction: row;
        > .logo {
            > a {
                > img {
                    width: 8.6vw;
                }
            }
        }
        > .menu {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            > a {
                color: #ffffff80;
                font-family: 'NanumSquareEB';
                font-size: 1.5vw;
                & + a {
                    margin-left: 3.6vw;
                }
                &.active {
                    color: #fff;
                }
            }
        }
        > .lang {
            display: flex;
            justify-content: center;
            align-items: center;
            > button {
                color: #ffffff80;
                position: relative;
                &.active {
                    color: #fff;
                }
                & + button {
                    margin-left: 1.5vw;
                }
                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                    width: 0%;
                    height: 2px;
                    margin: 5px 0 0;
                    transition: all 0.2s ease-in-out;
                    transition-duration: 0.3s;
                    opacity: 1;
                    background-color: #fff;
                }
                &:hover:after {
                    width: 100%;
                    opacity: 1;
                }
            }
        }
        > .mobile {
            display: none;
        }
    }
}

@media screen and (max-width: 800px) {
    .top-wrap {
        padding: 11.7vw 5.3vw;
        > .body {
            > .logo {
                > a {
                    > img {
                        width: 20.58vw;
                    }
                }
            }
            > .menu {
                display: none;
            }
            > .lang {
                display: none;
            }
            > .mobile {
                flex: 1;
                display: block;
                text-align: right;
                > button {
                    > img {
                        height: 5.33vw;
                    }
                }
            }
        }
        > .mobile-menu {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: #000;
            z-index: 100;
            > .body {
                display: flex;
                flex-direction: column;
                height: 100%;
                > .top {
                    display: flex;
                    flex-direction: row;
                    padding: 11.7vw 5.3vw;
                    > .logo {
                        > button {
                            > img {
                                width: 20.58vw;
                            }
                        }
                    }
                    > .menu {
                        flex: 1;
                        display: block;
                        text-align: right;
                        > button {
                            > img {
                                height: 5.33vw;
                            }
                        }
                    }
                }
                > .lang {
                    padding-bottom: 12.26vw;
                    padding-right: 8vw;
                    text-align: right;
                    > button {
                        font-family: 'NanumSquareEB';
                        color: #ffffff80;
                        font-size: 4.8vw;
                        &.active {
                            color: #fff;
                        }
                    }
                    > span {
                        color: #ffffff80;
                        font-size: 4.8vw;
                        margin-left: 5vw;
                        margin-right: 5vw;
                    }
                }
                > .menu {
                    display: flex;
                    flex-direction: column;
                    padding-left: 8vw;
                    > a {
                        font-family: 'NanumSquareEB';
                        color: #fff;
                        font-size: 6.4vw;
                        & + a {
                            margin-top: 15.2vw;
                        }
                    }
                }
                > .sns {
                    flex: 1;
                    text-align: right;
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 10.7vw;
                    padding-right: 8vw;
                    justify-content: flex-end;
                    > a {
                        > img {
                            width: 9.06vw;
                            height: 9.06vw;
                        }
                        & + a {
                            margin-top: 6.7vw;
                        }
                    }
                }
            }
        }
    }
}