.advisor-wrap {
    > .body {
        display: flex;
        flex-direction: row;
        > .left {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 2vw;
            > button {
                width: 3.38vw;
                height: 3.38vw;
                background-image: url('../../assets/images/drawable-xxhdpi/fanc_left.png');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                &.hidden {
                    opacity: 0;
                }
            }
        }
        > .center {
            flex: 1;
            > .page-wrap {
                position: relative;
                height:23.5vw;
                > .page {
                    position: absolute;
                    width: 100%;
                    height:23.5vw;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    opacity: 0;
                    &.current {
                        opacity: 1;
                    }
                    &:nth-child(1) {
                        background-image: url('../../assets/images/drawable-xxhdpi/team_advisor_1.png')
                    }
                    &:nth-child(2) {
                        background-image: url('../../assets/images/drawable-xxhdpi/team_advisor_2.png')
                    }
                    &:nth-child(3) {
                        background-image: url('../../assets/images/drawable-xxhdpi/team_advisor_3.png')
                    }
                    &:nth-child(4) {
                        background-image: url('../../assets/images/drawable-xxhdpi/team_advisor_4.png')
                    }
                    &:nth-child(5) {
                        background-position: left;
                        background-image: url('../../assets/images/drawable-xxhdpi/team_advisor_5.png')
                    }
                }
                &.en {
                    > .page {
                        &:nth-child(1) {
                            background-image: url('../../assets/images/drawable-xxhdpi/team_advisor_1_en.png')
                        }
                        &:nth-child(2) {
                            background-image: url('../../assets/images/drawable-xxhdpi/team_advisor_2_en.png')
                        }
                        &:nth-child(3) {
                            background-image: url('../../assets/images/drawable-xxhdpi/team_advisor_3_en.png')
                        }
                        &:nth-child(4) {
                            background-image: url('../../assets/images/drawable-xxhdpi/team_advisor_4_en.png')
                        }
                        &:nth-child(5) {
                            background-position: left;
                            background-image: url('../../assets/images/drawable-xxhdpi/team_advisor_5_en.png')
                        }
                    }
                }
            }
        }
        > .right {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 2vw;
            > button {
                width: 3.38vw;
                height: 3.38vw;
                background-image: url('../../assets/images/drawable-xxhdpi/fanc_right.png');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                &.hidden {
                    opacity: 0;
                }
            }
        }
    }
    > .navigation {
        margin-top: 7vw;
        text-align: center;
        > button {
            width: 0.8vw;
            height: 0.8vw;
            border-radius: 50%;
            background-color: #ffffff19;
            &.active {
                background-color: #ffffff99;
            }
            & + button {
                margin-left: 1.5vw;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .advisor-wrap {
        > .body {
            > .left {
                padding-right: 2.5vw;
                > button {
                    width: 8.5vw;
                    width: 8.5vw;
                    background-image: url('../../assets/images/drawable-xxhdpi/fi_chevron_left.png');
                }
            }
            > .center {
                > .page-wrap {
                    height:105vw;
                    > .page {
                        width: 100%;
                        height:105vw;
                        &:nth-child(1) {
                            background-image: url('../../assets/images/drawable-xxhdpi/advisor_1_mobile.png')
                        }
                        &:nth-child(2) {
                            background-image: url('../../assets/images/drawable-xxhdpi/advisor_2_mobile.png')
                        }
                        &:nth-child(3) {
                            background-image: url('../../assets/images/drawable-xxhdpi/advisor_3_mobile.png')
                        }
                        &:nth-child(4) {
                            background-image: url('../../assets/images/drawable-xxhdpi/advisor_4_mobile.png')
                        }
                        &:nth-child(5) {
                            background-image: url('../../assets/images/drawable-xxhdpi/advisor_5_mobile.png')
                        }
                    }
                    &.en {
                        > .page {
                            &:nth-child(1) {
                                background-image: url('../../assets/images/drawable-xxhdpi/advisor_1_mobile_eng.png')
                            }
                            &:nth-child(2) {
                                background-image: url('../../assets/images/drawable-xxhdpi/advisor_2_mobile_eng.png')
                            }
                            &:nth-child(3) {
                                background-image: url('../../assets/images/drawable-xxhdpi/advisor_3_mobile_eng.png')
                            }
                            &:nth-child(4) {
                                background-image: url('../../assets/images/drawable-xxhdpi/advisor_4_mobile_eng.png')
                            }
                            &:nth-child(5) {
                                background-position: center;
                                background-image: url('../../assets/images/drawable-xxhdpi/advisor_5_mobile_eng.png')
                            }
                        }
                    }
                }
            }
            > .right {
                padding-left: 2.5vw;
                > button {
                    width: 8.5vw;
                    height: 8.5vw;
                    background-image: url('../../assets/images/drawable-xxhdpi/fi_chevron_right.png');
                }
            }
        }
        > .navigation {
            margin-top: 7vw;
            text-align: center;
            > button {
                width: 2.1vw;
                height: 2.1vw;
                border-radius: 50%;
                background-color: #ffffff19;
                &.active {
                    background-color: #ffffff99;
                }
                & + button {
                    margin-left: 2.1vw;
                }
            }
        }
    }
}