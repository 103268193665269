.celebs-wrap {
    margin-top: 7vw;
    > .page-wrap {
        overflow-x: hidden;
        white-space: nowrap;
        > img {
            height: 10.4vw;
            animation: pc 20s infinite linear;
        }
    }
}

@media screen and (max-width: 800px) {
    .celebs-wrap {
        margin-top: 8vw;
        > .page-wrap {
            > img {
                height: 20.2vw;
                animation: mobile 20s infinite linear;
            }
        }
    }
}

@keyframes pc {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-40vw);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes mobile {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-180vw);
    }
    100% {
        transform: translateX(0);
    }
}