.product-wrap {
    padding-bottom: 13vw;
    .big-title {
        font-family: 'NanumSquareEB';
        color: #fff;
        text-align: center;
        font-size: 2.8vw;
        line-height: 3.65vw;
        margin-top: 14.5vw;
        margin-bottom: 8.5vw;
    }
    > .top {
        padding-left: 13.5vw;
        padding-right: 13.5vw;
        text-align: center;
        position: relative;
        > video {
            position: absolute;
            width: 60%;
            top: 0%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.6;
            z-index: -1;
        }
        > .middle-title {
            color: #fff;
            font-family: 'NanumSquareEB';
            font-size: 2.6vw;
            margin-top: 7.5vw;
            margin-bottom: 2vw;
        }
        > .info {
            display: flex;
            flex-direction: row;
            & + .info {
                margin-top: 2vw;
            }
            &.mobile {
                display: none;
            }
            .subtitle {
                height: 3.75vw;
            }
            .product {
                &.first {
                    height: 9.3vw;
                    width: 31.45vw;
                }
                &.second {
                    height: 10.1vw;
                    width: 23.07vw;
                }
                &.third {
                    height: 12.1vw;
                    width: 27.6vw;
                }
                &.top {
                    width: 22.9vw;
                    > img {
                        width: 100%;
                    }
                }
                &.bottom {
                    width: 35.8vw;
                    > img {
                        width: 100%;
                    }
                }
            }
            h1 {
                color: #fff;
                font-family: 'NanumSquareEB';
                font-size: 2.6vw;
            }
            > .left {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                
                > h1 {
                    text-align: left;
                }
                > pre {
                    text-align: left;
                    margin-top: 2vw;
                    color: #fff;
                    font-size: 1.25vw;
                    line-height: 2.2vw;
                }
            }
            > .right {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                text-align: right;
                > pre {
                    margin-top: 2vw;
                    color: #fff;
                    font-size: 1.25vw;
                    line-height: 2.2vw;
                }
                > .product {
                    align-self: flex-end;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .product-wrap {
        padding-bottom: 13vw;
        .big-title {
            font-size: 6.4vw;
            line-height: 3.65vw;
            margin-top: 19.2vw;
            margin-bottom: 32vw;
        }
        > .top {
            padding-left: 5.3vw;
            padding-right: 5.3vw;
            text-align: center;
            > .middle-title {
                font-size: 5.3vw;
                margin-top: 7.5vw;
                margin-bottom: 2vw;
            }
            > video {
                width: 100%;
            }
            > .info {
                display: flex;
                flex-direction: column;
                &.pc {
                    display: none;
                }
                &.mobile {
                    display: block;
                    > img {
                        width: 100%;
                        & + img {
                            margin-top: 5.3vw;
                        }
                    }
                }
                & + .info {
                    margin-top: 10.6vw;
                }

                &:nth-child(3) {
                    flex-direction: column-reverse;
                }

                .subtitle {
                    height: 3.75vw;
                }
                .product {
                    &.top {
                        width: 68.8vw;
                        > img {
                            width: 100%;
                        }
                        &:before {
                            content: "NFT MARKET PLACE";
                            font-size: 5.3vw;
                            font-family: 'NanumSquareEB';
                            color: #fff;
                            display: block;
                            margin-bottom: 5.3vw;
                        }
                    }
                    &.bottom {
                        width: 75.4vw;
                        > img {
                            width: 100%;
                        }
                        &:before {
                            content: "FANC MYSTERY BOX";
                            font-size: 5.3vw;
                            font-family: 'NanumSquareEB';
                            color: #fff;
                            display: block;
                            margin-bottom: 5.3vw;
                        }
                    }
                }
                h1 {
                    font-size: 5.3vw;
                    display: none;
                }
                > .left {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    > h1 {
                        text-align: center;
                    }
                    > pre {
                        text-align: center;
                        margin-top: 8vw;
                        font-size: 3.7vw;
                        line-height: 6.72vw;
                        white-space: normal;
                    }
                }
                > .right {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    > pre {
                        margin-top: 8vw;
                        font-size: 3.7vw;
                        line-height: 6.72vw;
                    }
                    > .product {
                        align-self: center;
                    }
                }
            }
        }
    }
}