.roadmap-wrap {
    padding-left: 13.5vw;
    padding-right: 13.5vw;
    > .pc {
        > .body {
            position: relative;
            > .line {
                position: absolute;
                width: 100%;
                bottom: 1.2vw;
                z-index: -1;
                > img {
                    width: 100%;
                }
            }
            > .page-wrap {
                overflow-x: auto;
                white-space: nowrap;
                > img {
                    height: 18.4vw;
                    & + img {
                        margin-left: 3vw;
                    }
                }
            }
        }
        > .navigation-wrap {
            margin-top: 11vw;
            position: relative;
            > .line {
                position: absolute;
                width: 100%;
                border-top: 1px solid #ffffff4c;
                top: 0.65vw;
                z-index: -1;
            }
            > .mark-wrap {
                display: flex;
                flex-direction: row;
                > .mark {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    > .circle-wrap {
                        height: 1.8vw;
                        position: relative;
                        > .white {
                            width: 0.5vw;
                            height: 0.5vw;
                            border-radius: 50%;
                            background-color: #fff;
                            position: absolute;
                            top: 0.4vw;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                        > img {
                            position: absolute;
                            top: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 1.3vw;
                            height: 1.3vw;
                        }
                    }
                    > .label {
                        color: #fff;
                        font-size: 0.7vw;
                    }
                }
            }
        }    
    }
    > .mobile {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .roadmap-wrap {
        padding-left: 5.3vw;
        padding-right: 5.3vw;
        > .pc {
            display: none;
        }
        > .mobile {
            display: block;
            text-align: center;
            > img {
                width: 100%;
            }
            > button {
                width: 44.5vw;
                margin-top: 4vw;
                > img {
                    width: 100%;
                }
            }
        }
    }
}