.home-wrap {
    min-height: 47vw;
    position: relative;
    > video {
        position: absolute;
        width: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.6;
    }
    > .center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        > img {
            width: 14.5vw;
            animation: topAni 1s;
        }
        > p {
            color: #fff;
            font-size: 1.25vw;
            line-height: 1.8vw;
            margin-top: 3.1vw;
            margin-bottom: 4.2vw;
            animation: middleAni 1.5s;
        }
        > a {
            font-family: 'NanumSquareB';
            color: #fff;
            border: 1px solid #fff;
            font-size: 1.25vw;
            padding: 1.2vw 3.4vw;
            border-radius: 2vw;
            animation: middleAni 1.5s;
            display: inline-block;
            &:hover {
                background-color: #523ee8;
                border: 1px solid #523ee8;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .home-wrap {
        min-height: 140vw;
        position: relative;
        overflow-x: hidden;
        > video {
            width: 140%;
        }
        > .center {
            width: 60vw;
            > img {
                width: 48vw;
            }
            > p {
                color: #fff;
                font-size: 4.25vw;
                line-height: 5.86vw;
                margin-top: 9.6vw;
                margin-bottom: 10.6vw;
            }
            > a {
                background-color: #523ee8;
                border: 1px solid #523ee8;
                font-size: 4.26vw;
                padding: 5.3vw 13.3vw;
                border-radius: 9vw;
            }
        }
    }
}

@keyframes topAni {
    from {
        opacity: 0;
        transform: translateY(-5vw);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes middleAni {
    0% {
        opacity: 0;
        transform: translateY(5vw);
    }
    25% {
        opacity: 0;
        transform: translateY(5vw);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}