@font-face {
  font-family: 'NanumSquareB';
  src: url(./assets/fonts/NanumSquareB.ttf) format('truetype');
}

@font-face {
  font-family: 'NanumSquareEB';
  src: url(./assets/fonts/NanumSquareEB.ttf) format('truetype');
}

@font-face {
  font-family: 'NanumSquareL';
  src: url(./assets/fonts/NanumSquareL.ttf) format('truetype');
}

@font-face {
  font-family: 'NanumSquareR';
  src: url(./assets/fonts/NanumSquareR.ttf) format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'NanumSquareR';
}

a,
a:link,
a:visited {
  text-decoration: none;
  cursor: pointer;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}