.about-wrap {
    padding-bottom: 13vw;
    .big-title {
        font-family: 'NanumSquareEB';
        color: #fff;
        text-align: center;
        font-size: 2.8vw;
        line-height: 3.65vw;
        margin-top: 14.5vw;
        margin-bottom: 8.5vw;
    }
    > .top {
        padding-left: 13.5vw;
        padding-right: 13.5vw;
        position: relative;
        > video {
            position: absolute;
            width: 60%;
            top: 0%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.6;
            z-index: -1;
        }
        > .info {
            display: flex;
            flex-direction: row;
            > .left {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                > h1 {
                    color: #fff;
                    font-family: 'NanumSquareEB';
                    font-size: 2.6vw;
                }
            }
            > .right {
                flex: 1;
                > pre {
                    color: #fff;
                    font-size: 1.25vw;
                    text-align: right;
                    line-height: 2.2vw;
                }
            }
        }
    }
    > .roadmap {

    }
    > .advisor {
        padding-right: 6.7vw;
        padding-left: 6.7vw;
    }
    > .partners {
        padding-left: 13.5vw;
        padding-right: 13.5vw;
        > img {
            width: 100%;
        }
    }
}

@media screen and (max-width: 800px) {
    .about-wrap {
        padding-bottom: 13vw;
        .big-title {
            font-size: 6.4vw;
            line-height: 3.65vw;
            margin-top: 10.6vw;
            margin-bottom: 8vw;
        }
        > .top {
            > .big-title {
                margin-top: 19.2vw;
                margin-bottom: 32vw;
            }
            > video {
                width: 100%;
            }
            padding-left: 5.3vw;
            padding-right: 5.3vw;
            text-align: center;
            > .info {
                display: flex;
                flex-direction: column;
                > .left {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    justify-content: center;
                    > h1 {
                        font-size: 5.3vw;
                    }
                }
                > .right {
                    flex: 1;
                    > pre {
                        text-align: center;
                        margin-top: 8vw;
                        font-size: 3.7vw;
                        line-height: 6.72vw;
                        white-space: normal;
                    }
                }
            }
        }
        > .roadmap {
    
        }
        > .advisor {
            padding-right: 5.3vw;
            padding-left: 5.3vw;
        }
        > .partners {
            padding-left: 5.3vw;
            padding-right: 5.3vw;
            > img {
                width: 100%;
            }
        }
    }
}